.operation-table {
  margin: 0;
  color: #273b4e;
}

.crumb {
  transform: translateX(-12px) translateY(-8px);
}

.caret-right {
  transform: translateY(1px);
}

.kebab-options {
  z-index: 2;
  cursor: pointer;
}

.search-filter {
  min-width: 250px !important;
}

input:focus {
  outline: none;
}
