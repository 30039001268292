.tab-section-border {
  border-bottom: 1px solid #0750e2;
}

.tabs {
  max-width: 25%;
}

.tabfalse:hover {
  color: #0750e2;
}

.tab-active {
  color: #0750e2;
}

.outline-active {
  border: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 3px solid #0750e2;
}

.selector-menu {
  max-height: 50vh;
  overflow-y: scroll;
  border: 1px solid #e2e5eb;
  align-items: flex-start;
  width: 150px;
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  background-color: white !important;
  margin-top: 4px;
}

.domain-filter {
  width: 95%;
}

.card {
  border-radius: 4px !important;
  border: 1px solid #e2e5eb !important;
  padding: 12px !important;
}

.reports {
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.reportsHead { 
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #e2e5eb;
  padding: 5px;
}
